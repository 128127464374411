import * as React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    useLocation
} from 'react-router-dom';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
    MainListItems,
    SecondaryListItems
} from '../../components/dashboard/listItems';
import Chart from '../../components/dashboard/Chart';
import Deposits from '../../components/dashboard/Deposits';
import Orders from '../../components/dashboard/Users';
import Account from './Account';
import ToggleColorMode from '../../components/ToggleColorMode';
import Alerts from './Alerts';
import AccountManagement from './AccountManagement';
import Dashboard from './Dashboard';
import LocationsPage from './LocationsPage';
import CareerManagement from './CareerManagement';
import { useUserContext } from '../../utilities/context/userContext';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9)
            }
        })
    }
}));

// TODO remove, this demo shouldn't need to reset the theme.

export default function DashboardRoot({ mode, toggleColorMode }) {
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const authManager = useUserContext();

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr: '24px' // keep right padding when drawer closed
                    }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' })
                        }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}>
                        Dashboard
                    </Typography>
                    {
                        // <IconButton color='inherit'>
                        //     <Badge badgeContent={4} color='secondary'>
                        //         <NotificationsIcon />
                        //     </Badge>
                        // </IconButton>
                    }

                    <ToggleColorMode
                        mode={mode}
                        toggleColorMode={toggleColorMode}
                    />
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1]
                    }}>
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    <MainListItems />
                    <Divider sx={{ my: 1 }} />
                    <SecondaryListItems />
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: theme =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto'
                }}>
                <Toolbar />
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="account" element={<Account />} />
                    {authManager.user.isAdmin === true
                        ? <Route
                              path="account–management"
                              element={<AccountManagement />}
                          />
                        : null}
                    {authManager.user.isAdmin === true ||
                    authManager.user.role === 'office'
                        ? <React.Fragment>
                              <Route
                                  path="operational-alerts"
                                  element={<Alerts />}
                              />

                              <Route
                                  path="locations"
                                  element={<LocationsPage />}
                              />
                              <Route
                                  path="job-postings"
                                  element={<CareerManagement />}
                              />
                          </React.Fragment>
                        : null}
                    <Route path="*" element={<Dashboard />} />
                </Routes>
            </Box>
        </Box>
    );
}
