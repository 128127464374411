import * as React from 'react';

import { Divider, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import LocationCards from '../components/LocationCards';
import ceo from '../assets/pics/ceo.jpg';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import { Fade } from "react-awesome-reveal";

function BasicCard({ title, description }) {
    const { palette } = useTheme();
    return (
        <Card sx={{ minWidth: '90%' }}>
            <CardContent>
                <Typography
                    component='h2'
                    variant='h4'
                    textAlign='center'
                    // sx={{ fontSize: 18 }}
                    color={
                        palette.mode === 'light'
                            ? primary_color
                            : primary_color_dark
                    }
                    gutterBottom>
                    {title}
                </Typography>

                <Typography variant='body2' color='text.secondary'>
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
}
const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';
const fSize = 18;

function AboutUs({ mode, toggleColorMode }) {
    const { palette } = useTheme();

    return (
        <React.Fragment>
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
            <Fade>
            <Box sx={{ bgcolor: 'background.default' }}>
                <Box
                    sx={(theme) => ({
                        width: '100%',
                        p: 4,
                        backgroundImage:
                            theme.palette.mode === 'light'
                                ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                                : `linear-gradient(#02294F, ${alpha(
                                      '#090E10',
                                      0.0
                                  )})`,
                        backgroundSize: '100% 20%',
                        backgroundRepeat: 'no-repeat',
                        pt: 15,
                    })}>
                    <Typography
                        component='h2'
                        variant='h4'
                        textAlign='center'
                        color={
                            palette.mode === 'light'
                                ? primary_color
                                : primary_color_dark
                        }
                        sx={{
                            fontSize: 'clamp(3rem, 10vw, 4rem)',
                        }}>
                        About us
                    </Typography>
                    <Box sx={{ width: '100%', p: 2 }}>
                        <Typography
                            textAlign='center'
                            color='text.secondary'
                            fontSize={fSize}
                            sx={{
                                alignSelf: 'center',
                            }}>
                            At National Courier Express (NCE), we uphold the
                            belief that every promise our vendor makes to their
                            client is a promise we must keep. We don't just see
                            courier delivery as a means to an end, but as an
                            opportunity to exceed expectations. When you choose
                            NCE, your package is in the hands of our own
                            professional and bonded drivers, each with a wealth
                            of experience spanning over 25 years in medical
                            courier delivery.
                        </Typography>
                        <Typography
                            textAlign='center'
                            color='text.secondary'
                            fontSize={fSize}
                            sx={{
                                alignSelf: 'center',
                                marginTop: 5,
                            }}>
                            At NCE, we pride ourselves on our ability to meet
                            even the most unique needs of organizations such as
                            laboratories, pharmacies, and medical supplies
                            providers. Our courier services are not
                            one-size-fits-all but tailored to your specific
                            requirements. We provide hospitals, labs, and more
                            with on-demand, same-day, and next-day delivery
                            solutions for their medical supplies, such as
                            medical specimens, medical equipment, bloodwork, and
                            pharmaceuticals.
                        </Typography>
                    </Box>
                    <Container
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: { xs: 3, sm: 6 },
                        }}>
                        <Box
                            sx={{
                                width: '80%',
                                p: 2,
                                textAlign: 'center',
                                alignSelf: 'center',
                            }}>
                            <Typography
                                component='h2'
                                variant='h2'
                                color={
                                    palette.mode === 'light'
                                        ? primary_color
                                        : primary_color_dark
                                }>
                                Company Overview
                            </Typography>
                            <br />
                            <Grid container spacing={6}>
                                <Grid item xs={12} md={6}>
                                    <BasicCard
                                        title={'Our Vision'}
                                        description={` To be the leading provider of reliable
                            and efficient medical supply logistics,
                            ensuring that every healthcare
                            stakeholder has seamless access to
                            essential resources, ultimately
                            contributing to healthier communities
                            and improved patient outcomes worldwide.`}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicCard
                                        title={'Our Mission'}
                                        description={`Ensure the safe and prompt delivery of
                                essential medical supplies to empower
                                pharmacies, healthcare providers,
                                laboratories, and patients in their
                                journey toward better health outcomes.`}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                    <Box id={'ceo'} sx={{ py: { xs: 8, sm: 10 } }}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={12}>
                                <Stack
                                    direction='column'
                                    justifyContent='center'
                                    alignItems='flex-start'
                                    spacing={2}
                                    useFlexGap
                                    sx={{
                                        width: '100%',
                                        display: { xs: 'flex', sm: 'flex' },
                                    }}>
                                    <Card
                                        variant='outlined'
                                        sx={{
                                            p: 3,
                                            height: 'fit-content',
                                            width: '100%',
                                            background: 'none',
                                            borderColor: (theme) => {
                                                if (
                                                    theme.palette.mode ===
                                                    'light'
                                                ) {
                                                    return 'grey.200';
                                                }
                                                return 'grey.800';
                                            },
                                        }}>
                                        <Typography
                                            color={
                                                palette.mode === 'light'
                                                    ? primary_color
                                                    : primary_color_dark
                                            }
                                            variant='body2'
                                            fontSize={26}>
                                            Message from the CEO
                                        </Typography>

                                        <Box
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                textAlign: 'left',
                                                flexDirection: {
                                                    xs: 'column',
                                                    md: 'row',
                                                },
                                                alignItems: { md: 'center' },
                                                gap: 2.5,
                                            }}>
                                            <div
                                                style={{
                                                    // width: '70%',
                                                    // height: 100,
                                                    // flex: 1,
                                                    display: 'block',
                                                    // flexDirection: 'row',
                                                }}>
                                                <Box
                                                    component={'img'}
                                                    src={ceo}
                                                    sx={{
                                                        borderRadius: 5,
                                                        margin: 2,
                                                        height: {
                                                            xs: 370,
                                                            sm: 300,
                                                            md: 300,
                                                            lg: 300,
                                                        },
                                                        float: {
                                                            // xs: 'none',
                                                            // sm: 'none',
                                                            xs: 'left',
                                                            sm: 'left',
                                                            md: 'left',
                                                            lg: 'left',
                                                        },
                                                    }}
                                                    alt='logo of NCE'
                                                />
                                                <Typography
                                                    color='text.secondary'
                                                    variant='body2'
                                                    // textAlign={'justify'}

                                                    fontSize={fSize}
                                                    sx={{
                                                        my: 0.5,
                                                        // mt: {
                                                        //     xs: 40,
                                                        //     sm: 0.5,
                                                        //     md: 0.5,
                                                        // }
                                                        // textAlign: 'justify',
                                                    }}>
                                                    After serving as the EPR
                                                    Program Manager and Lead
                                                    Manager for the National
                                                    Health Service of England
                                                    and Wales for more than 4
                                                    years, I am honored to write
                                                    this communication as the
                                                    Chief Executive Officer,
                                                    marking a significant
                                                    milestone as we celebrate
                                                    our company's second
                                                    anniversary. Throughout the
                                                    years, we have dedicated
                                                    ourselves to building our
                                                    brand and team to
                                                    effectively navigate the
                                                    ever-changing landscape of
                                                    medical logistics.
                                                </Typography>
                                                <br />
                                                <Typography
                                                    color='text.secondary'
                                                    variant='body2'
                                                    fontSize={fSize}
                                                    textAlign={'justify'}
                                                    sx={{ my: 0.5 }}>
                                                    The last two quarters have
                                                    seen significant
                                                    transformation within our
                                                    organization, including:
                                                </Typography>
                                                <Typography
                                                    color='text.secondary'
                                                    variant='body2'
                                                    fontSize={fSize}
                                                    sx={{ my: 2.5 }}>
                                                    • Doubling our workforce and
                                                    promoting diversity and
                                                    gender equality.
                                                </Typography>
                                                <Typography
                                                    color='text.secondary'
                                                    variant='body2'
                                                    fontSize={fSize}
                                                    sx={{ my: 2.5 }}>
                                                    • Becoming the preferred
                                                    medical courier partner for
                                                    home infusion providers in
                                                    the Georgia market.
                                                </Typography>
                                                <Typography
                                                    color='text.secondary'
                                                    variant='body2'
                                                    fontSize={fSize}
                                                    sx={{ my: 2.5 }}>
                                                    • Expanding our presence in
                                                    the markets of North and
                                                    South Carolina, Alabama,
                                                    Florida, and Texas.
                                                </Typography>

                                                <br/>
                                                <Typography
                                                    component='div'
                                                    color='text.secondary'
                                                    variant='body2'
                                                    fontSize={fSize}
                                                    sx={{ my: 2.5, mt: 2 }}>
                                                    Amidst these changes, our
                                                    unwavering commitment to our
                                                    clients remains constant. At{' '}
                                                    <Box
                                                        fontWeight='bold'
                                                        display='inline'>
                                                        National Courier Express
                                                    </Box>
                                                    , we believe that every
                                                    commitment made by our
                                                    vendors to their clients is
                                                    a commitment we are
                                                    duty-bound to uphold. This
                                                    unwavering dedication is the
                                                    cornerstone of our success
                                                    and will continue to guide
                                                    us in the future.
                                                </Typography>

                                                <Typography
                                                    component='div'
                                                    color='text.secondary'
                                                    variant='body2'
                                                    fontSize={fSize}
                                                    sx={{ my: 2.5, mt: 2 }}>
                                                    Looking ahead, our focus is
                                                    on accelerating the
                                                    realization of our growth
                                                    strategy and strengthening
                                                    the foundation of our brand
                                                    - providing top-notch
                                                    medical courier delivery
                                                    solutions for our partners.
                                                    I am filled with enthusiasm
                                                    about this journey and
                                                    firmly believe that the best
                                                    of {'  '}
                                                    <Box
                                                        fontWeight='bold'
                                                        display='inline'>
                                                        National Courier Express
                                                    </Box>{' '}
                                                    is yet to come.
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        textTransform: 'none',
                                                        textAlign: 'left',
                                                        mt: 8,
                                                    }}>
                                                    <Typography
                                                        color='text.secondary'
                                                        variant='body2'
                                                        fontSize={fSize}
                                                        sx={{ my: 0.2 }}>
                                                        Joyce Uzo
                                                    </Typography>
                                                    <Typography
                                                        color='text.secondary'
                                                        variant='body2'
                                                        fontSize={fSize}>
                                                        Chief Executive Officer
                                                    </Typography>
                                                    <Typography
                                                        color='text.secondary'
                                                        variant='body2'
                                                        fontSize={fSize}>
                                                        National Courier Express
                                                    </Typography>
                                                </Box>
                                            </div>
                                        </Box>
                                    </Card>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
            <Divider />
            <Footer />
            </Fade>
        </React.Fragment>
    );
}

export default AboutUs;
