import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import {
    Divider,
    MenuItem,
    useTheme,
    FormGroup,
    Checkbox,
    TextField,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import AttachFileIcon from '@mui/icons-material/AttachFile'
import validator from 'validator';

import { green } from '@mui/material/colors';
import SnackbarBase from '../SnackbarBase';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TextEditor from './TextEditor';
import { MuiFileInput } from 'mui-file-input'
import { stateLabelValues, roles } from '../../utils/useFull';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';

export default function GeneralModal({
    IconButton = () => {
        return <PersonAddIcon />;
    },
    IconButtonEnd = () => {
        return null;
    },
    title = 'open me',
    formFields = [],
    description = 'Please fill out this form.',
    useSub = false,
    SubBtn = (callback) => {},
    handleSend = async () => {},
    editMode = false
}) {
    const { palette } = useTheme();

    const [isSuccess, setIsSuccess] = useState(false);
    const [didError, setDidError] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [open, setOpen] = useState(false);

    const [data, setData] = useState({});
    const [errorData, setErrorData] = useState({});
    const [errorMsg, setErrorMsg] = useState('');

    const handleSetData = (name, value) => {
        let newDataObj = { ...data };
        newDataObj[name] = value;
        setData(newDataObj);
    };

    const [fileData, setFileData] = React.useState(null)

  const handleFileChange = (newValue) => {
    setErrorData(oldData => ({ ...oldData, file_upload: false }));
    handleSetData('file_upload', newValue);
    setFileData(newValue)
  }

    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700]
            }
        })
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setErrorMsg('');
        setFileData(null)
        setSuccess(false);
        setLoading(false);
        setDidError(false);
        if (editMode === false) {
            let dataKeys = Object.keys(data);
            if (dataKeys.length > 0) {
                for (let i = 0; i < dataKeys.length; i++) {
                    const dataKey = dataKeys[i];
                    if (dataKey === 'file_upload') {
                        data[dataKey] = null
                        setFileData(null);
                    } 
                    data[dataKey] = ''
                    
                }
            }
        }
        
    };


    const OpenBtn = () => {
        return (<Button
            onClick={handleOpen}
            sx={{ my: 2, height: 1 }}
            variant="outlined"
            startIcon={<IconButton />}
            endIcon={<IconButtonEnd />}>
            <Typography id="modal-modal-title" variant="p" component="p">
                {title}
            </Typography>
        </Button>)
    }

    const validateEmail = email => {
        handleSetData('email', email);
        if (validator.isEmail(email)) {
            setErrorData(oldData => ({ ...oldData, email: false }));
            return false;
        } else {
            setErrorData(oldData => ({ ...oldData, email: true }));
            return true;
        }
    };
    const validatePhone = phone => {
        handleSetData('phone', phone);
        if (validator.isMobilePhone(phone)) {
            setErrorData(oldData => ({ ...oldData, phone: false }));
            return false;
        } else {
            setErrorData(oldData => ({ ...oldData, phone: true }));
            return true;
        }
    };
    const handleSubmit = async () => {
        let hasErrors = false;
        setSuccess(false);
        setLoading(true);
        if (!Object.keys(data).length > 0) {
            hasErrors = true;
            setDidError(true);
        }

        let newErrorObj = {};
        debugger
        for (const property in data) {
            console.log(`${property}: ${data[property]}`);

            if (property !== 'file_upload' && !data[property].length > 0 && property !== 'isAdmin') {
                newErrorObj[property] = true;
                hasErrors = true;
            }

            if (property == 'email') {
                let res = !validator.isEmail(data.email);

                newErrorObj.email = res;
                if (res === true) {
                    hasErrors = true;
                }
            }
            if (property == 'file_upload') {
                if (!fileData) {
                    newErrorObj.file_upload = true;
                    hasErrors = true;
                } else {
                    newErrorObj.file_upload = false;
                }
            }

            if (property == 'message') {
                if (data[property].length < 20) {
                    newErrorObj.message = true;
                    hasErrors = true;
                }
            }
            if (property == 'phone') {
                let res = !validator.isMobilePhone(data.phone);
                newErrorObj.phone = res;
                if (res === true) {
                    hasErrors = true;
                }
            }
        }

        if (Object.keys(errorData).length > 0) {
            for (const property in errorData) {
                if (errorData[property] === true) {
                    hasErrors = true;
                }
            }
        }
        setErrorData(oldData => ({ ...oldData, ...newErrorObj }));
        if (hasErrors === true) {
            setSuccess(false);
            setLoading(false);
            return;
        } else {
            console.log(data);
            let formData = data;
            if (Object.hasOwn(formData, 'role')) {
                if (formData.role == 'admin') {
                    debugger
                    formData.isAdmin = true;
                } else {
                    formData.isAdmin = false;
                }
                debugger
            }
            try {
                let res = await handleSend(data);
                if (!res.ok) {
                    const errorData = await res.json();
                    throw new Error(errorData.message);
                }
                setSuccess(true);
                setLoading(false);
                setIsSuccess(true);
                setErrorData({})
                handleClose();
            } catch (error) {
                setErrorMsg(error.message);
                setDidError(true);
                setSuccess(false);
                setLoading(false);
                console.error('Error:', error);
            }
        }
    };

    useEffect(() => {
        let newObj = {};
        let newErrorObj = {};
        for (let i = 0; i < formFields.length; i++) {
            const field = formFields[i];
            const { name, value } = field;
            newObj[name] = value;
            newErrorObj[name] = false;
        }
        setData(newObj);
        setErrorData(newErrorObj);
        return () => {};
    }, []);

    return (
        <div>
            <SnackbarBase
                isOpen={didError}
                setIsOpen={setDidError}
                type={'error'}
                message={
                    'there was a problem submitting your information. Please try again later'
                }
            />
            <SnackbarBase
                isOpen={isSuccess}
                setIsOpen={setIsSuccess}
                type={'success'}
                message={'your info was successfully sent'}
            />
            {
                useSub === false ?
                <OpenBtn/> : <SubBtn callback={handleOpen}/>
                
            }
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500
                    }
                }}>
                <Fade in={open}>
                    <Box
                        sx={{
                            ...style,
                            width: { xs: '90%', sm: '90%', md: '60%' },
                            maxHeight: '95vh',
                            borderRadius: 10,
                            borderColor: theme => {
                                if (theme.palette.mode === 'light') {
                                    return 'grey.200';
                                }
                                return 'grey.800';
                            }
                        }}>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            color={
                                palette.mode === 'light'
                                    ? secondary_color
                                    : secondary_color_dark
                            }>
                            {title}
                        </Typography>
                        <Typography
                            id="modal-modal-description"
                            sx={{ mt: 4 }}
                            color="text.secondary">
                            {description}
                        </Typography>

                        <Divider />
       
                        <List sx={{maxHeight: {xs: '60vh', sm: '70vh', md: '70vh', lg: '60vh'}, overflow: 'auto' }}>

                        {formFields.map(field => {
                            const {
                                lable,
                                name,
                                value,
                                type,
                                validText
                            } = field;

                            if (type === 'multiline_text') {
                                return (
                                    <React.Fragment>
                                        <TextField
                                            error={errorData[name]}
                                            id={name}
                                            label={lable}
                                            variant="standard"
                                            helperText={
                                                errorData[name] === true
                                                    ? validText
                                                    : ''
                                            }
                                            value={data[name]}
                                            required
                                            multiline
                                            minRows={4}
                                            maxRows={4}
                                            onChange={({ target }) => {
                                                handleSetData(
                                                    name,
                                                    target.value
                                                );
                                                if (target.value.length >= 20) {
                                                    // let errorObj = {
                                                    //     ...errorData
                                                    // };
                                                    // errorObj[name] = false
                                                    // setErrorData(errorObj);
                                                    setErrorData(oldData => ({
                                                        ...oldData,
                                                        [name]: false
                                                    }));
                                                } else {
                                                    // setErrorData(oldData => ({ ...oldData, [name]: true }));
                                                }
                                            }}
                                            sx={{ width: '100%' }}
                                        />
                                        <Typography
                                            id="modal-modal-description"
                                            sx={{ mt: 4 }}
                                            color="text.secondary">
                                            Minimum characters left {' '}
                                            {data[name]?.length <= 20
                                                ? 20 - data[name].length
                                                : 0}
                                        </Typography>
                                    </React.Fragment>
                                );
                            } 
                             if (type === 'multi') {
                                // debugger
                                // data[name] = value
                                return (  
                                    <FormControl
                                        fullWidth
                                        
                                        sx={{ 
                                            mt: 2,
                                            mb: 2, 
                                            width: '30%' 
                                        }}>
                                        <InputLabel id={name}>
                                        {lable}
                                        </InputLabel>
                                        <Select
                                            labelId={name}
                                            label={lable}
                                            value={data[name]}
                                            onChange={({ target }) => {
                                                handleSetData(
                                                    name,
                                                    target.value
                                                );

                                                setErrorData(oldData => ({
                                                        ...oldData,
                                                        [name]: false
                                                    }));
                                            }}
                                            >
                                            {roles.map(
                                                (role, index) => {
                                                    return (
                                                        <MenuItem
                                                            key={index}
                                                            value={
                                                                role.value
                                                            }>
                                                            {' '}{role.label}{' '}
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                        </Select>
                                        <Typography
                                            textAlign="center"
                                            color="text.secondary"
                                            sx={{
                                                alignSelf: 'center',
                                                width: {
                                                    sm: '100%',
                                                    md: '80%'
                                                }
                                            }}>
                                            Please select a user role.
                                        </Typography>
                                    </FormControl>)
                            }
                            if (type === 'state_select') {
                                // debugger
                                // data[name] = value
                                return (  
                                    <FormControl
                                        fullWidth
                                        
                                        sx={{ 
                                            mt: 2,
                                            mb: 2, 
                                            // width: '50%' 
                                        }}>
                                        <InputLabel id="state-select">
                                            State
                                        </InputLabel>
                                        <Select
                                            labelId="state-select"
                                            label={'State'}
                                            value={data[name]}
                                            onChange={({ target }) => {
                                                handleSetData(
                                                    name,
                                                    target.value
                                                );
                                                setErrorData(oldData => ({
                                                        ...oldData,
                                                        [name]: false
                                                    }));
                                            }}
                                            >
                                            {stateLabelValues.map(
                                                (state, index) => {
                                                    return (
                                                        <MenuItem
                                                            key={index}
                                                            value={
                                                                state.value
                                                            }>
                                                            {' '}{state.label}{' '}
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                        </Select>
                                        <Typography
                                            textAlign="center"
                                            color="text.secondary"
                                            sx={{
                                                alignSelf: 'center',
                                                width: {
                                                    sm: '100%',
                                                    md: '80%'
                                                }
                                            }}>
                                            Please select a state.
                                        </Typography>
                                    </FormControl>)
                            }
                            if (type === 'file_upload') {
                                return (
                                    <Box>
                                    <Divider />
                                        <Typography
                                                id="modal-modal-description"
                                                sx={{ mt: 2, mb: 1 }}
                                                color="text.secondary">
                                                {lable}*
                                        </Typography>
                                        <MuiFileInput required value={fileData} onChange={handleFileChange}
                                        InputProps={{
                                            inputProps: {
                                              accept: field.fileAccepts
                                            },
                                            startAdornment: <AttachFileIcon />,
                                          }}
                                        clearIconButtonProps={{
                                            title: "Remove",
                                            children: <CloseIcon fontSize="small" />
                                          }} />
                                          <Typography
                                                id="modal-modal-description"
                                                sx={{ mt: 2, mb: 1 }}
                                                color="text.secondary">
                                                {validText}
                                        </Typography>
                                        <Typography
                                                id="modal-modal-description"
                                                sx={{ mt: 2, mb: 1 }}
                                                color="red">
                                                {errorData[type] === true
                                                    ? 'Please upload a file'
                                                    : ''}
                                        </Typography>
                                        
                                    </Box>
                                )
                            }
                            if (type === 'fancy_text') {
                                return (
                                    <TextEditor
                                        title={'Description'}
                                        description={`Please enter all the job details here.`}
                                        value={data[name]}
                                        maxHeight={'25vh'}
                                        onChange={({ target }) => {
                                            handleSetData(name, target.value);
                                        }}
                                    />
                                );
                            }
                            if (type === 'bool') {
                                return (
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={data[name]}
                                                    onChange={({ target }) => {
                                                        handleSetData(
                                                            name,
                                                            target.checked
                                                        );
                                                    }}
                                                    inputProps={{
                                                        'aria-label':
                                                            'controlled'
                                                    }}
                                                />
                                            }
                                            label={lable}
                                        />
                                    </FormGroup>
                                );
                            } else {
                                return (
                                    <React.Fragment>
                                        <TextField
                                            required
                                            error={errorData[name]}
                                            id={name}
                                            label={lable}
                                            variant="standard"
                                            helperText={
                                                errorData[name] === true
                                                    ? validText
                                                    : ''
                                            }
                                            value={data[name]}
                                            onChange={
                                                type === 'email'
                                                    ? ({ target }) =>
                                                          validateEmail(
                                                              target.value
                                                          )
                                                    : type === 'phone_number'
                                                      ? ({ target }) =>
                                                            validatePhone(
                                                                target.value
                                                            )
                                                      : ({ target }) => {
                                                            handleSetData(
                                                                name,
                                                                target.value
                                                            );
                                                            if (
                                                                target.value
                                                                    .length > 5
                                                            ) {
                                                                let errorObj = {
                                                                    ...errorData
                                                                };
                                                                errorObj[
                                                                    name
                                                                ] = false;
                                                                setErrorData(
                                                                    errorObj
                                                                );
                                                            }
                                                        }
                                            }
                                            sx={{ width: '40%' }}
                                        />
                                        <Divider sx={{ mb: 2 }} />
                                    </React.Fragment>
                                );
                            }
                        })}
                        
                          
                          
                        <Box sx={{ width: '100%', my: 2 }}>
                            <Divider sx={{ mb: 2 }} />
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 4 }}
                                color="text.secondary">
                                Please enter all required * fields.
                            </Typography>
                            {errorMsg.length > 0
                                ? <Typography
                                      id="modal-modal-description"
                                      sx={{ mt: 4 }}
                                      color="red">
                                      {errorMsg}
                                  </Typography>
                                : null}
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                            <Button onClick={handleClose}>
                                <CloseIcon style={{ color: 'red' }} />
                            </Button>
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Button
                                    variant="contained"
                                    sx={buttonSx}
                                    disabled={loading}
                                    // onClick={handleButtonClick}
                                    onClick={() => handleSubmit(errorData)}>
                                    Submit
                                    <CheckIcon style={{ color: 'green' }} />
                                </Button>

                                {loading &&
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px'
                                        }}
                                    />}
                            </Box>
                        </Box>
                        </List>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
