import React, { useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { Box, Button, Divider, Grid, LinearProgress } from '@mui/material';
import { useUserContext } from '../../utilities/context/userContext';
import SnackbarBase from '../SnackbarBase';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import GeneralModal from './GeneralModal';

export default function Users() {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [didError, setDidError] = useState(false);

    const authManager = useUserContext();
    const handleGetUsers = async () => {
        setLoading(true);
        try {
            const response = await fetch('/api/user/', {
                method: 'Get', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `token ${authManager.authToken}`,
                },
            });
            if (response.ok) {
                const result = await response.json();
                setUsers(result);
                debugger;
            } else {
                setDidError(true);
            }
        } catch (error) {
            setDidError(true);
            setLoading(false);
        }
        setLoading(false);
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
            handleGetUsers();
        }, 600);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const handlePasswordSubmit = async (newData, userData) => {
      debugger
        try {
            const response = await fetch(`/api/user/${userData.user._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `token ${authManager.authToken}`,
                },
                body: JSON.stringify(newData),
            });
            debugger
            if (!response.ok) {
                return response;
            }
            // const data = await response.json();
            // authManager.handleLogin(data);
            debugger
            return response;

        } catch (error) {
          debugger
            // authManager.handleLoginOut();
            console.log(error);
        }
    };

    const handleDelete = async (user) => {
        setLoading(true);
        try {
            const response = await fetch(`/api/user/${user._id}`, {
                method: 'delete', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `token ${authManager.authToken}`,
                },
            });
            if (response.ok) {
                const result = await response.json();
                if (result.acknowledged === true) {
                    debugger;
                    handleGetUsers();
                }
            } else {
                setDidError(true);
            }
        } catch (error) {
            setDidError(true);
            setLoading(false);
        }
        setLoading(false);
    };
    const formFields = [
        {
            lable: 'Username',
            name: 'userName',
            value: '',
            type: 'text',
            validText: 'please enter the username',
        },
        {
            lable: 'Firstname',
            name: 'firstName',
            value: '',
            type: 'text',
            validText: 'please enter the first name',
        },
        {
            lable: 'Lastname',
            name: 'lastName',
            value: '',
            type: 'text',
            validText: 'please enter the last name',
        },
        {
            lable: 'Email',
            name: 'email',
            value: '',
            type: 'email',
            validText: 'please enter a valid email',
        },
        {
            lable: 'Password',
            name: 'password',
            value: '',
            type: 'text',
            validText: 'please select a valid password',
        },
        // {
        //     lable: 'Is an Admin?',
        //     name: 'isAdmin',
        //     value: false,
        //     type: 'bool',
        //     validText: 'please select an option',
        // },
        {
            lable: 'Role',
            name: 'role',
            value: 'user',
            type: 'multi',
            validText: 'please select an option',
        },
    ];
    return (
        <React.Fragment>
            <SnackbarBase
                isOpen={didError}
                setIsOpen={setDidError}
                type={'error'}
                message={
                    'there was a problem retrieving user information. Please try again later'
                }
            />
            <Title>Users</Title>
            {loading ? <LinearProgress /> : null}
            <Divider />
            <Grid container>
                <Grid
                    item
                    xs={10}
                    style={{
                        display: 'flex',
                        gap: '1rem',
                        alignItems: 'center',
                    }}>
                    <Button
                        onClick={handleGetUsers}
                        variant='outlined'
                        sx={{ m: 2 }}
                        startIcon={<RefreshIcon />}>
                        refresh
                    </Button>

                    <GeneralModal
                        formFields={formFields}
                        title='Create user'
                        handleSend={async (data) => {
                            let url = '/api/user/signup';
                            const response = await fetch(url, {
                                method: 'POST', // or 'PUT'
                                headers: {
                                    'Content-Type': 'application/json',
                                    authorization: `token ${authManager.authToken}`,
                                },
                                body: JSON.stringify(data),
                            });

                            if (response.ok) {
                                const result = await response.json();
                                handleGetUsers();
                                console.log('Success:', result);
                                return response;
                            }
                            if (!response.ok) {
                                return response;
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Username</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>First name</TableCell>
                        <TableCell>Last name</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell align='right'></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user._id}>
                            <TableCell>{user.userName}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.firstName}</TableCell>
                            <TableCell>{user.lastName}</TableCell>
                            <TableCell>{`${user.role}`}</TableCell>
                            <TableCell align='right'>
                            <GeneralModal
                            IconButton={() => null}
                            formFields={[
                                {
                                    lable: 'Username',
                                    name: 'userName',
                                    value: user.userName,
                                    type: 'text',
                                    validText: 'please enter the username',
                                },
                                {
                                    lable: 'Firstname',
                                    name: 'firstName',
                                    value: user.firstName,
                                    type: 'text',
                                    validText: 'please enter the first name',
                                },
                                {
                                    lable: 'Lastname',
                                    name: 'lastName',
                                    value: user.lastName,
                                    type: 'text',
                                    validText: 'please enter the last name',
                                },
                                {
                                    lable: 'Email',
                                    name: 'email',
                                    value: user.email,
                                    type: 'email',
                                    validText: 'please enter a valid email',
                                },
                            //     {
                            //       lable: 'Is an Admin?',
                            //       name: 'isAdmin',
                            //       value: false,
                            //       type: 'bool',
                            //       validText: 'please select an option',
                            //   },
                            {
                                lable: 'Role',
                                name: 'role',
                                value: user.role,
                                type: 'multi',
                                validText: 'please select an option',
                            },
                            ]}
                            title='Change user info'
                            handleSend={async (data) => {
                                debugger;
                                // return await handlePasswordSubmit(data, user);
    
                                const response = await fetch(
                                    `/api/user/${user._id}`,
                                    {
                                        method: 'PUT',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            authorization: `token ${authManager.authToken}`,
                                        },
                                        body: JSON.stringify(data),
                                    }
                                );
                                debugger;
                                if (!response.ok) {
                                    return response;
                                }
                                // const resData = await response.json();
                                // // authManager.handleLogin(resData);
                                handleGetUsers();
                                // debugger;
                                return response;
    
                                // } catch (error) {
                                //   debugger
                                //     // authManager.handleLoginOut();
                                //     console.log(error);
                                // }
                            }}
                        />
                                <GeneralModal
                                    IconButton={() => null}
                                    formFields={[
                                        {
                                            lable: 'Password',
                                            name: 'password',
                                            value: '',
                                            type: 'text',
                                            validText:
                                                'please select a valid password',
                                        },
                                    ]}
                                    title='Reset Password'
                                    handleSend={async (data)=> {
                                      debugger
                                      // return await handlePasswordSubmit(data, user);
                                      
                                        const response = await fetch(`/api/user/${user._id}`, {
                                            method: 'PUT',
                                            headers: {
                                                'Content-Type': 'application/json',
                                                authorization: `token ${authManager.authToken}`,
                                            },
                                            body: JSON.stringify(data),
                                        });
                                        debugger
                                        if (!response.ok) {
                                            return response;
                                        }
                                        // const data = await response.json();
                                        // authManager.handleLogin(data);
                                        debugger
                                        return response;
                            
                                    // } catch (error) {
                                    //   debugger
                                    //     // authManager.handleLoginOut();
                                    //     console.log(error);
                                    // }
                                    }}
                                />
                                <Button
                                    onClick={() => {
                                        handleDelete(user);
                                    }}
                                    variant='outlined'
                                    color='error'
                                    startIcon={<DeleteIcon />}>
                                    Delete
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
